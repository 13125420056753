export const USER_DOLOGIN = 'user/login'
export const USER_SETTOKEN = 'user/settoken'
export const USER_DOLOGOUT = 'user/logout'
export const USER_REGISTER = 'user/register'
export const USER_UNREGISTER = 'user/unregister'
export const USER_VERIFY = 'user/verify'
export const USER_RESET = 'user/reset'
export const USER_CHECKSALT = 'user/checksalt'
export const USER_CHANGE_PASSWORD = 'user/changepassword'
export const USER_SETLOGGIN_STATE = 'user/setlogginstate'

export const USER_READTOKEN = 'user/userreadtoken'
