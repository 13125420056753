
export const HomeView = resolve => {
    require.ensure(['./Home.vue'], () => {
        resolve(require('./Home.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const FarmsView = resolve => {
    require.ensure(['./Farms.vue'], () => {
        resolve(require('./Farms.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const FarmRootView = resolve => {
    require.ensure(['./FarmRoot.vue'], () => {
        resolve(require('./FarmRoot.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const FarmView = resolve => {
    require.ensure(['./Farm.vue'], () => {
        resolve(require('./Farm.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const StoreRootView = resolve => {
    require.ensure(['./StoreRoot.vue'], () => {
        resolve(require('./StoreRoot.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const StorePromosView = resolve => {
    require.ensure(['./StorePromos.vue'], () => {
        resolve(require('./StorePromos.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const PromoView = resolve => {
    require.ensure(['./Promo.vue'], () => {
        resolve(require('./Promo.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
export const MultiFarmPromosView = resolve => {
    require.ensure(['./MultiFarmPromos.vue'], () => {
        resolve(require('./MultiFarmPromos.vue'))
    }, 'home') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}

