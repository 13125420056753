export default {
    data: function () {
		return {
            options: {
                dialog: false,
                fullScreen: true,
                color: 'red',
                message: '',
                title: '',
                formName: null,
                data: {}
            }
		}
	}
}
