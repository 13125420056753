export const APP_GETDRAWERSTATUS = 'app/getdrawerstatus'
export const APP_TOGGLEDRAWERSTATUS = 'app/toggledrawer'
export const APP_SETDRAWERSTATUS = 'app/setdrawer'

export const APP_SETAPPBACKGROUND = 'app/setbackground'
export const APP_SETAPPBARCOLOR = 'app/setappbarcolor'
export const APP_SETAPPFOOTERCOLOR = 'app/setappfootercolor'

export const APP_SETFARMLISTTYPE = 'app/farmlisttype'

export const APP_SETPROMOS_SCROLLPOSITION = 'app/setscrollpromosposition'
export const APP_SETSTORES_SCROLLPOSITION = 'app/setscrollstoresposition'
export const APP_RESETSCROLLERS = 'app/resetscrollers'

export const APP_SETFARMPROMOS_CURRENTTAB = 'app/setfarmpromostab'
export const APP_SETPROMEFILES_CURRENTTAB = 'app/setpromefilestab'
export const APP_RESETTABS = 'app/resettabs'
