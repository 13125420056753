import client from 'api-client'
import * as storeActions from '../actions/store'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loading: false,
    loaded: false,
    error: null,
    stores: [],
    currentStore: {},
    cache: {}
}

const getters = {}

const actions = {

    [storeActions.STORE_GET_STORES]: async ({
        dispatch,
        commit
    }, farmId) => {
        try {
            // console.log('sono lo state', state.loaded)
            if (state.loaded == true) {
                return true
            }
            commit(storeActions.STORE_LOADING)
            const stores = await client.fetchFarmStores(farmId)
            commit(storeActions.STORE_LOADED, stores)
            console.log(stores)
            return true
        } catch (err) {
            commit(storeActions.STORE_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [storeActions.STORE_FETCH_STORES]: async ({
        dispatch,
        commit
    }, farmId) => {
        try {
            // console.log('sono lo state', state.loaded)
            const stores = await client.fetchFarmStores(farmId)
            return stores
        } catch (err) {
            return null
        }
    },

    [storeActions.STORE_GET_STORE]: async ({
        dispatch,
        commit
    }, storeId) => {
        try {
            console.log('sono lo state', storeId)
            if (state.loaded) {
                const selected = state.stores.filter(st => {
                    return st.storeId === storeId
                })

                if (selected.length === 1) {
                    state.currentStore = selected[0].cliente
                    return selected[0].cliente
                }
            }
            const store = await client.fetchStore(storeId)
            state.currentStore = store
            //            console.log(store)
            return store
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [storeActions.STORE_GET_PROMO_COUNT]: async ({
        dispatch,
        commit
    }, storeId) => {
        try {
            if (state.cache[storeId] === undefined) {
                const promos = await client.fetchStorePromoCount(storeId)
                state.cache[storeId] = promos;
                return promos
            } else return state.cache[storeId]
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [storeActions.STORE_UPDATE_PROMO_COUNT]: ({
        dispatch,
        commit
    }, data) => {
        console.log('aggiorno promo count')
        state.cache[data.storeId] = data.count
    }
}

const mutations = {
    [storeActions.STORE_LOADING]: (state) => {
        state.loaded = false
        state.loading = true
        state.error = null
        state.stores = []
        state.currentStore = {}
    },

    [storeActions.STORE_LOADED]: (state, stores) => {
        state.stores = stores
        state.loading = false
        state.loaded = true
    },

    [storeActions.STORE_ERROR]: (state, error) => {
        state.error = error
        state.stores = []
        state.loaded = false
        state.loading = false
        state.currentStore = {}
    },

    [storeActions.STORE_RESET_STORE]: (state) => {
        state.currentStore = {}
    },

    [storeActions.STORE_RESET_STORES]: (state) => {
        state.loaded = false
        state.loading = false
        state.error = null
        state.stores = []
        state.currentStore = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
