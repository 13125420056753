export const CLUSTER_GET_CLUSTERS = 'cluster/getclusters';
export const CLUSTER_GET_CLUSTER = 'cluster/getcluster';
export const CLUSTER_SET_CLUSTER = 'cluster/setcluster';
export const CLUSTER_DELETE_CLUSTER = 'cluster/deletecluster';

export const CLUSTER_LOADING = 'cluster/loading'
export const CLUSTER_LOADED = 'cluster/loaded'
export const CLUSTER_ERROR = 'cluster/error'
export const CLUSTER_RESET_CLUSTERS = 'cluster/reset'
export const CLUSTER_ADD_OR_MODIFY_CLUSTER = 'cluster/add'
