import client from 'api-client'
import * as promoActions from '../actions/promo'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loaded: false,
    loading: false,
    error: null,
    promo: {},
    toApprove: {}
}

const getters = {}

const actions = {
    [promoActions.PROMO_FETCHPROMO]: async ({
        dispatch,
        commit
    }, data) => {
        try {
            // console.log('caricolo la promo', data.farmId, data.promoId)
            commit(promoActions.PROMO_LOADING)
            let toApprove = {}
            const promo = await client.fetchPromo(data.farmId, data.promoId)
            // se la promo non è approvata devo caricare anche quella da approvare
            if (promo.Approvata === false) {
                toApprove = await client.fetchToApprovePromo(data.farmId, data.promoId)
                console.log('to approve', toApprove, typeof toApprove)
                if (typeof toApprove === 'string') {
                    toApprove = null
                }
            }
            commit(promoActions.PROMO_LOADED, { promo, toApprove })
            // console.log(promo)
            return true
        } catch (err) {
            commit(promoActions.PROMO_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [promoActions.PROMO_UPDATEPROMO]: async ({
        dispatch,
        commit
    }, promo) => {
        try {
            const formData = new FormData()
            if (promo.imageFile !== undefined) {
                formData.append("icoop_image", promo.imageFile)
                // delete promo.imageFile
            }
            if (promo.audioFile !== undefined) {
                formData.append("icoop_audio", promo.audioFile)
                // delete promo.audioFile
            }
            if (promo.videoFile !== undefined) {
                formData.append("icoop_video", promo.videoFile)
                // delete promo.videoFile
            }
            if (promo.attachFile !== undefined) {
                formData.append("icoop_attachment", promo.attachFile)
                // delete promo.attachFile
            }

            formData.append('promo', JSON.stringify(promo));
            // Object.keys(promo).forEach(key => {
            //     formData.append(key, promo[key])
            // })

            // formData.append('promo', promo)

            console.log(promo)

            const status = await client.updatePromo(formData)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [promoActions.PROMO_NEWPROMO]: async ({
        dispatch,
        commit
    }, data) => {
        try {
            const promo = data.promo
            const formData = new FormData()
            if (promo.imageFile !== undefined) {
                formData.append("icoop_image", promo.imageFile)
                // delete promo.imageFile
            }
            if (promo.audioFile !== undefined) {
                formData.append("icoop_audio", promo.audioFile)
                // delete promo.audioFile
            }
            if (promo.videoFile !== undefined) {
                formData.append("icoop_video", promo.videoFile)
                // delete promo.videoFile
            }
            if (promo.attachFile !== undefined) {
                formData.append("icoop_attachment", promo.attachFile)
                // delete promo.attachFile
            }

            formData.append('promo', JSON.stringify(promo));
            // Object.keys(promo).forEach(key => {
            //     formData.append(key, promo[key])
            // })

            // formData.append('promo', promo)

            console.log(promo)

            const createdPromo = await client.createPromo(data.farmId, formData)
            return createdPromo
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [promoActions.PROMO_APPROVEPROMO]: async ({
        dispatch,
        commit
    }, data) => {
        try {
            const formData = new FormData()

            formData.append('promo', JSON.stringify(data.promo));
            formData.append('approved', JSON.stringify(data.toApprove));
            formData.append('farmId', data.farmId);

            const status = await client.approvePromo(formData)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [promoActions.PROMO_REJECTPROMO]: async ({
        dispatch,
        commit
    }, data) => {
        try {
            const formData = new FormData()

            formData.append('promo', JSON.stringify(data.promo));
            formData.append('rejected', JSON.stringify(data.toApprove));

            const status = await client.rejectPromo(formData)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [promoActions.PROMO_DELETEPROMO]: async ({
        dispatch,
        commit
    }, promoId) => {
        try {
            const status = await client.deletePromo(promoId)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    }
}

const mutations = {
    [promoActions.PROMO_LOADING]: (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
        state.promo = {}
        state.toApprove = {}
    },

    [promoActions.PROMO_RESET]: (state) => {
        state.loading = false
        state.loaded = false
        state.error = null
        state.promo = {}
        state.toApprove = {}
    },

    [promoActions.PROMO_LOADED]: (state, { promo, toApprove }) => {
        state.promo = promo
        state.toApprove = toApprove
        state.loading = false
        state.loaded = true
    },

    [promoActions.PROMO_ERROR]: (state, error) => {
        state.loading = false
        state.loaded = false
        state.error = error
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
