<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            @keydown.esc="cancel"
            :fullscreen="options.fullScreen"
            transition="dialog-bottom-transition"
            :hide-overlay="options.fullScreen"
            persistent
            scrollable
            :width="computedWidth"
        >
            <v-card flat :height="computedHeight">
                <v-card-title class="card-toolbar">
                    <v-app-bar dark color="primary">
                        <v-toolbar-title>{{ options.title }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click.native="cancel">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>
                <v-card-text class="card-content">
                    <component
                        v-if="options.formName !== null"
                        :is="loadAsyncComponent"
                        :model="options.data"
                        @saveData="saveData"
                    ></component>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import mixins from "./mixins";

export default {
    mixins: [mixins],
    data() {
        return {
            dialog: false
        };
    },
    computed: {
        computedWidth() {
            return this.options.fullScreen ? "100vw" : "80vw";
        },
        computedHeight() {
            return this.options.fullScreen ? "100%" : "80vh";
        },
        loadAsyncComponent() {
            // console.log(`./forms/${this.options.formName}.vue`)
            return () => ({
                component: import(`./forms/${this.options.formName}.vue`),
                //   loading: AwesomeSpinner,
                //   error: SadFaceComponent,
                timeout: 1000
            });
        }
    },
    methods: {
        mergeData(data) {
            this.options = { ...this.options, ...data };
            this.dialog = true;
        },
        setProgress(show) {
            if (show === true) {
                this.$waitingTask.open({ message: "Attendere prego ...." });
            } else {
                this.$waitingTask.destroy();
            }
        },
        agree() {
            return this.options.promiseResolver();
        },
        cancel() {
            return this.options.promiseRejecter();
        },
        forceCloseAll() {
            this.dialog = false;
        },
        saveData(data) {
            return this.options.promiseResolver(data);
        }
    },
    mounted() {}
};
</script>

<style scoped>
.card-toolbar {
    padding: 0 !important;
}

.card-content {
    padding: 0 !important;
    margin-top: 24px;
}

/* .toolbar-wrapper {
    flex: 1;
}

.component-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
} */
</style>
