// import devtools from '@vue/devtools'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import i18n from '@/plugins/i18n'

import ConfirmDialog from './plugins/confirmDialog/index'
import DialogManager from './plugins/formDialog/index'
import WaitingTask from './plugins/waitingTask/index'

import routerMiddleware from './middleware'

import router from './router'
import store from './store'

routerMiddleware(router)

Vue.config.productionTip = false

// if (process.env.NODE_ENV === 'development') {
//     devtools.connect( /* host, port */ )
// }

Vue.use(ConfirmDialog, { vuetify })
Vue.use(WaitingTask, { vuetify })
Vue.use(DialogManager, { vuetify, store })

new Vue({
    i18n,
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
