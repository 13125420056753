import Vue from 'vue'
import Vuex from 'vuex'

import appModule from './modules/app'
import userModule from './modules/user'
import snackbarModule from './modules/snackbar'

import storeModule from './modules/store'
import promeCategoryModule from './modules/promeCategory'
import promoCategoryModule from './modules/promoCategory'
import promeFileModule from './modules/promeFile'
import farmModule from './modules/farm'
import storePromosModule from './modules/storePromos'
import farmPromosModule from './modules/farmPromos'
import filterModule from './modules/filter'
import promoModule from './modules/promo'
import promoMultiFarmModule from './modules/multiFarmPromo'
import clusterModule from './modules/cluster'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        snackbarModule,
        appModule,
        userModule,
        promeCategoryModule,
        promoCategoryModule,
        promeFileModule,
        storeModule,
        farmModule,
        promoModule,
        promoMultiFarmModule,
        storePromosModule,
        farmPromosModule,
        filterModule,
        clusterModule
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
    plugins: []
})
