import client from 'api-client'
import * as promoActions from '../actions/storePromos'
import * as snackbarActions from '../actions/snackbar'
import * as storeActions from '../actions/store'

const state = {
    loading: false,
    loaded: false,
    error: null,
    promos: []
}

const getters = {}

const actions = {
    [promoActions.STOREPROMOS_GET_STORE_PROMOS]: async ({ dispatch, commit }, storeId) => {
        try {
            console.log('recupero le promo dallo store', state.loaded, storeId)
            if (state.loaded) {
                return true
            }
            commit(promoActions.STOREPROMOS_LOADING)
            const promos = await client.fetchStorePromos(storeId);
            dispatch(storeActions.STORE_UPDATE_PROMO_COUNT, {storeId: storeId, count: promos.count})
            commit(promoActions.STOREPROMOS_LOADED, promos)
            return true
        }
        catch(err) {
            commit(promoActions.STOREPROMOS_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    }
}

const mutations = {
    [promoActions.STOREPROMOS_LOADING]: (state) => {
        state.loaded = false
        state.loading = true
        state.error = null
        state.promos = []
    },

    [promoActions.STOREPROMOS_LOADED]: (state, promos) => {
        state.promos = promos
        state.loading = false
        state.loaded = true
    },

    [promoActions.STOREPROMOS_ERROR]: (state, error) => {
        state.error = error
        state.promos = []
        state.loaded = false
        state.loading = false
    },

    [promoActions.STOREPROMOS_RESET]: (state) => {
        state.loaded = false
        state.loading = false
        state.error = null
        state.promos = []
    },

    [promoActions.STOREPROMOS_APPEND]: (state, promo) => {
        state.promos.push(promo)
    },

    [promoActions.STOREPROMOS_REMOVE]: (state, promoId) => {
        state.promos.filter(promo => {
            return promo.IdComunicazioneGenerica !== promoId
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
