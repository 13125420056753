import * as filterActions from '../actions/filter'

const state = {
    storeFilter: {
        text: '',
        all: false
    },
    promoFilter: {
        text: '',
        fromEnabled: false,
        toEnabled: false,
        from: new Date().toISOString(),
        to: new Date().toISOString(),
        all: true,
        expired: false,
        deleted: false,
        toPublish: true,
        suspended: true,
        active: true
    },
    promeFileFilter: {
        text: '',
        fromEnabled: false,
        toEnabled: false,
        from: new Date().toISOString(),
        to: new Date().toISOString(),
        expired: false
    },
    clustersFilter: {
        text: ''
    },
    multiFarmsFilter: {
        text: '',
        fromEnabled: false,
        toEnabled: false,
        from: new Date().toISOString(),
        to: new Date().toISOString(),
        all: true,
        expired: false,
        deleted: false,
        toPublish: true,
        suspended: true,
        active: true
    }
}

const getters = {}

const actions = {}

const mutations = {
    [filterActions.FILTER_STORES_SET_TEXT]: (state, text) => {
        state.storeFilter.text = text
    },
    [filterActions.FILTER_PROMOS_SET_TEXT]: (state, text) => {
        state.promoFilter.text = text
    },
    [filterActions.FILTER_MULTIFARMSPROMOS_SET_TEXT]: (state, text) => {
        state.multiFarmsFilter.text = text
    },
    [filterActions.FILTER_PROMEFILES_SET_TEXT]: (state, text) => {
        state.promeFileFilter.text = text
    },
    [filterActions.FILTER_CLUSTERS_SET_TEXT]: (state, text) => {
        state.clustersFilter.text = text
    },
    [filterActions.FILTER_STORES_SET_SWITCH]: (state, value) => {
        state.storeFilter.all = value
    },
    [filterActions.FILTER_PROMOS_SET_SWITCH]: (state, data) => {
        state.promoFilter[data.key] = data.value
    },
    [filterActions.FILTER_MULTIFARMSPROMOS_SET_SWITCH]: (state, data) => {
        state.multiFarmsFilter[data.key] = data.value
    },
    [filterActions.FILTER_PROMEFILES_SET_SWITCH]: (state, data) => {
        state.promeFileFilter[data.key] = data.value
    },
    [filterActions.FILTER_STORES_RESET]: (state) => {
        state.storeFilter = {
            text: '',
            all: false
        }
    }
}

export default {
    getters,
    state,
    actions,
    mutations
}
