import axios from "axios"
import router from '../router'
import tokenStorage from "./tokenStorage";


// import {
//     graphQlEndpoint
// } from '../utils/api'

// import DateTime from 'luxon/src/datetime.js'

const instance = axios.create({
    // baseURL: graphQlEndpoint,
    headers: {
        "content-type": "application/json"
    },
    timeout: 1200000 // 20 minuti di timeout
});

const readCsrfCookieAndSetHeader = () => {
    console.info('READING CSRF-TOKEN');
    if (document.cookie.indexOf('XSRF-TOKEN') > -1) {
        const v = document.cookie.match('(^|;) ?' + 'XSRF-TOKEN' + '=([^;]*)(;|$)');
        const r = v ? v[2] : '';
        // console.log(r);
        return r;
    } else {
        return '';
    }
}

// Add a request interceptor
const requestInterceptor = function (axiosInstance) {
    axiosInstance.interceptors.request.use(
        config => {

            // check for CSRF TOKEN
            // const token = readCsrfCookieAndSetHeader()
            // if (token.length > 0) {
            //     console.log('readToken', token)
            //     config.headers.__RequestVerificationToken = token
            // }
            if (config.url.indexOf('auth/login') !== -1) {
                return config
            }

            // Do something before request is sent
            // console.log('sono il request interceptor')
            if (tokenStorage.isAuthenticated()) {
                config.headers.Authorization = tokenStorage.getAuthenticationHeader()
                // config.headers.RefreshToken = TokenStorage.getRefreshToken()
                return config
            } else {
                return config
            }
        },
        error => {
            // Do something with request error
            // console.log('config error', error)
            return Promise.reject(error);
        }
    )
}

const responseInterceptor = function (instance) {
    instance.interceptors.response.use(response => response, error => {
        //
        // console.log('sono il response intercepto', error, error.response)

        if (error.response.status === 401) {
            console.log(error.response, instance.interceptors.response)

            if (tokenStorage.isAuthenticated()) { // devo provare a fare il refresh token
                tokenStorage.getNewRefreshToken()
                    .then(() => { // eseguito refresh token, rifaccio la richiesta
                        error.response.config.headers['Authorization'] = tokenStorage.getAuthenticationHeader();
                        return instance(error.response.config)
                    })
                    .catch(err => { // impossibile eseguire il refresh
                        console.log('cannot refresh token', err)
                        tokenStorage.clear();
                        router.go({ path: '/' })
                        return Promise.reject(err)
                    })
                    .finally(responseInterceptor(instance))
            }
            // qua dovresti fare il giro con il refresh token al limite

        }
        else throw error.response.data
        // console.log(error)
        // if (error.response.status === 401) {
        //     tokenStorage.clear()
        //     router.go({
        //         path: '/'
        //     })
        //     return Promise.reject(error)
        // }
        // console.log(config)
        // if (config.status === 401) { // per non sapere ne leggere ne scrivere provo il refresh token
        //     // console.log('intercettato non autorizzato!!')
        //     const originalRequest = config.request
        //     // provo a fare un refresh token
        //     // return store.dispatch(authTypes.AUTH_REFRESH)
        //     //     .then((result) => {
        //     //         // Resend the failed request whatever it was (GET, POST, PATCH) and return its response
        //     //         const token = store.getters[authTypes.AUTH_GET_TOKEN]
        //     //         // console.log('ok riprovo la richiesta')
        //     //         originalRequest.headers.Authorization = `Bearer ${token.access_token}`
        //     //         return Promise.resolve(originalRequest)
        //     //     })
        //     //     .catch((error) => {
        //             console.error('errore refresh token', config)
        //             return router.go({path: '/login'})
        //         // })
        // } else {
        //     return config
        // }
    })
}

requestInterceptor(instance);
responseInterceptor(instance);

export default instance
