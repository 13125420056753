import Vue from 'vue'

import client from 'api-client'
import * as clusterActions from '../actions/cluster'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loading: false,
    loaded: false,
    error: null,
    clusters: []
}

const getters = {};

const actions = {
    [clusterActions.CLUSTER_GET_CLUSTERS]: async ({
        dispatch,
        commit
    }, farmId) => {
        try {
            if (state.loaded) {
                return true;
            }
            commit(clusterActions.CLUSTER_LOADING)
            const clusters = await client.getGruppiConsorzio(farmId);
            console.log(clusters)
            commit(clusterActions.CLUSTER_LOADED, clusters)
            return true
        } catch (err) {
            commit(clusterActions.CLUSTER_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [clusterActions.CLUSTER_GET_CLUSTER]: async ({
        dispatch,
        commit
    }, groupId) => {
        try {
            const cluster = await client.getGruppoConsorzio(groupId)
            return cluster;
        } catch (error) {
            dispatch(snackbarActions.SNACKBAR_SHOW, error)
            return null
        }
    },

    [clusterActions.CLUSTER_SET_CLUSTER]: async ({
        dispatch,
        commit
    }, form) => {
        try {
            const result = await client.setGruppoConsorzio(form);
            return result;
        } catch (error) {
            dispatch(snackbarActions.SNACKBAR_SHOW, error)
            return null
        }
    }
}

const mutations = {
    [clusterActions.CLUSTER_LOADING]: (state) => {
        state.loaded = false
        state.loading = true
        state.error = null
        state.clusters = []
    },

    [clusterActions.CLUSTER_LOADED]: (state, clusters) => {
        state.clusters = clusters
        state.loading = false
        state.loaded = true
    },

    [clusterActions.CLUSTER_ERROR]: (state, error) => {
        state.error = error
        state.clusters = []
        state.loaded = false
        state.loading = false
    },

    [clusterActions.CLUSTER_RESET_CLUSTERS]: (state) => {
        state.loaded = false
        state.loading = false
        state.error = null
        state.clusters = []
    },

    [clusterActions.CLUSTER_ADD_OR_MODIFY_CLUSTER]: (state, cluster) => {
        const index = state.clusters.findIndex(a => a.IDGruppo === cluster.IDGruppo)
        console.log('sono la cipolla', index, cluster)
        if (index !== -1) {
            Vue.set(state.clusters, index, cluster)
        }
        else {
            console.log('prima', state.clusters.length)
            state.clusters.push(cluster)
            console.log('dopo', state.clusters.length)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
