import graphQl from '../../services/graphql'

import instance from '../../services/axiosInstance'
import {
    authLogin,
    authLogout,
    authConfirm,
    getFarms,
    getFarmStores,
    getStores,
    getStorePromos,
    getStore,
    getStorePromoCount,
    getFarm,
    getFarmPromos,
    fetchPromo,
    fetchToApprovePromo,
    updatePromo,
    updatePromoMulti,
    createPromo,
    approvePromo,
    rejectPromo,
    deletePromo,
    authGetPrivileges,
    fetchPromeCategories,
    fetchPromoCategories,
    fetchPromeFiles,
    deletePromeFile,
    getgruppiconsorzio,
    getgruppoconsorzio,
    setgruppoconsorzio,
    deletegruppoconsorzio,
    fetchMultiFarmPromos
} from '../../utils/api'

const localInstance = (url, method, data, headers) => {
    return instance({
            url: url,
            method: method,
            data: data || null,
            headers: headers !== null ? headers : { "content-type": "application/json" }
        })
        .then(response => {
            // console.log('sono il response', response)
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('errors')) {
                console.log('nel bridge', response)
                throw response.data.errors[0]
            }
            return response.data
        })
        .catch(err => {
            console.log('catchato dal bridge', err, JSON.parse(JSON.stringify(err)))
            // eslint-disable-next-line no-prototype-builtins
            if (err.hasOwnProperty('message')) {
                const msg = err.message.split('\n')[0]
                throw msg
            } else
                throw err
        })
}

export default {

    authLogin(credentials) {
        return localInstance(authLogin, 'POST', credentials)
    },

    authLogout() {
        return localInstance(authLogout, 'POST')
    },

    authConfirm(form) {
        return localInstance(`${authConfirm}?userId=${form.userId}&code=${form.code}`, 'GET')
    },

    getAuthPrivileges() {
        return localInstance(`${authGetPrivileges}`, 'POST')
    },

    fetchFarms() {
        return localInstance(getFarms, 'GET')
    },

    fetchFarm(farmId) {
        return localInstance(`${getFarm}/${farmId}`, 'GET')
    },

    fetchFarmStores(farm) {
        return localInstance(`${getFarmStores}/${farm}`, 'GET')
    },

    fetchFarmPromos(farm) {
        return localInstance(`${getFarmPromos}/${farm}`, 'GET')
    },

    fetchStores() {
        return localInstance(getStores, 'GET')
    },

    fetchStorePromos(storeId) {
        return localInstance(`${getStorePromos}/${storeId}`, 'GET')
    },

    fetchStore(storeId) {
        return localInstance(`${getStore}/${storeId}`, 'GET')
    },

    fetchStorePromoCount(storeId) {
        return localInstance(`${getStorePromoCount}/${storeId}`, 'GET')
    },

    fetchPromo(farmId, promoId) {
        return localInstance(`${fetchPromo}/${farmId}/${promoId}`, 'GET')
    },

    fetchMultiFarmPromos() {
        return localInstance(`${fetchMultiFarmPromos}`, 'GET')
    },

    fetchToApprovePromo(farmId, promoId) {
        return localInstance(`${fetchToApprovePromo}/${farmId}/${promoId}`, 'GET')
    },

    updatePromo(formData) {
        return localInstance(`${updatePromo}`, 'POST', formData, { "content-type": "multipart/form-data" })
    },

    updatePromoMulti(formData) {
        return localInstance(`${updatePromoMulti}`, 'POST', formData, { "content-type": "multipart/form-data" })
    },

    createPromo(farmId, formData) {
        return localInstance(`${createPromo}/${farmId}`, 'POST', formData, { "content-type": "multipart/form-data" })
    },

    approvePromo(formData) {
        return localInstance(`${approvePromo}`, 'POST', formData, { "content-type": "multipart/form-data" })
    },

    rejectPromo(formData) {
        return localInstance(`${rejectPromo}`, 'POST', formData, { "content-type": "multipart/form-data" })
    },

    deletePromo(promoId) {
        return localInstance(`${deletePromo}/${promoId}`, 'DELETE')
    },

    fetchPromeCategories(farmId) {
        return localInstance(`${fetchPromeCategories}/${farmId}`, 'GET')
    },

    fetchPromoCategories(farmId) {
        return localInstance(`${fetchPromoCategories}/${farmId}`, 'GET')
    },

    fetchPromeFiles(farmId) {
        return localInstance(`${fetchPromeFiles}/${farmId}`, 'GET')
    },

    deletePromeFile(fileId) {
        return localInstance(`${deletePromeFile}/${fileId}`, 'POST')
    },

    getGruppiConsorzio(farmId) {
        return localInstance(`${getgruppiconsorzio}/${farmId}`, 'GET')
    },

    getGruppoConsorzio(groupId) {
        return localInstance(`${getgruppoconsorzio}/${groupId}`, 'GET');
    },

    setGruppoConsorzio(group) {
        return localInstance(`${setgruppoconsorzio}`, 'POST', group);
    },

    deleteGruppoConsorzio(groupId) {
        return localInstance(`${deletegruppoconsorzio}/${groupId}`, 'DELETE');
    },
}
