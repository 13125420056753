import client from 'api-client'
import * as farmActions from '../actions/farm'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loading: false,
    loaded: false,
    error: null,
    farms: [],
    currentFarm: {}
}

const getters = {}

const actions = {
    [farmActions.FARM_GET_FARMS]: async ({ dispatch, commit }) => {
        try {
            // console.log('sono lo state', state.loaded)
            if (state.loaded == true) {
                return true
            }
            commit(farmActions.FARM_LOADING)
            const farms = await client.fetchFarms();
            commit(farmActions.FARM_LOADED, farms)
            return true
        }
        catch(err) {
            commit(farmActions.FARM_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [farmActions.FARM_GET_FARM]: async ({ dispatch, commit }, farmId) => {
        try {
            if (state.loaded) {
                const farm = state.farms.filter(f => {
                    return f.IDGruppo === farmId
                })
                if (farm.length === 1) {
                    state.currentFarm = farm[0]
                    return farm[0]
                }
            }
            const farm = await client.fetchFarm(farmId)
            state.currentFarm = farm
            return farm
        }
        catch(err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    }
}

const mutations = {
    [farmActions.FARM_LOADING]: (state) => {
        state.loaded = false
        state.loading = true
        state.error = null
        state.farms = []
        state.currentFarm = {}
    },

    [farmActions.FARM_LOADED]: (state, farms) => {
        state.farms = farms
        state.loading = false
        state.loaded = true
    },

    [farmActions.FARM_ERROR]: (state, error) => {
        state.error = error
        state.farms = []
        state.loaded = false
        state.loading = false
    },

    [farmActions.FARM_SET_CURRENT]: (state, farm) => {
        state.currentFarm = farm
    },

    [farmActions.FARM_UNSET_CURRENT]: (state) => {
        state.currentFarm = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
