<template>
    <router-view/>
</template>

<script>

export default {
}
</script>

<style>
    ::-webkit-scrollbar {
        display: none;
    }
</style>
