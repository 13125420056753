import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios'

import { restAction } from '@/utils/utils'
import { getLang } from '@/utils/api'

Vue.use(VueI18n);

export const supportedLanguages = 'it,en'
export const defaultLanguage = 'it'

const loadedLanguages = []

const i18n = new VueI18n({
    locale: 'it', // set locale
    fallbackLocale: 'it', // set fallback locale
    // messages, // set locale messages
});

function setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export const loadLanguageAsync = async (lang) => {

    // If the language was already loaded
    if (!loadedLanguages.includes(lang)) {
        const messages = await restAction(getLang, {lang: lang}, 'POST')
        i18n.setLocaleMessage(lang, messages)
        loadedLanguages.push(lang)
        setI18nLanguage(lang)
    }
    else {
        return setI18nLanguage(lang)
    }

    // If the same language
    // if (i18n.locale === lang) {
    //     return setI18nLanguage(lang)
    // }
}


export default i18n;
