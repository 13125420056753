/* eslint-disable no-prototype-builtins */
import client from 'api-client'

import * as userActions from '../actions/user'

import {
    authRegister,
    authReset,
    authCheckSalt,
    authChangePassword
} from '@/utils/api'

import { restAction } from '@/utils/utils'
import * as snackbarActions from '../actions/snackbar'

import tokenStorage from '../../services/tokenStorage'

const state = {
    logged: false,
    firstName: '',
    lastName: '',
    privileges : {
        isAdmin: false,
        addPromo: false,
        approveReject: false
    }
}

const getters = {}

const actions = {
    [userActions.USER_DOLOGIN]: async ({
        rootState,
        dispatch,
        commit
    }, credentials) => {
        try {
            // console.log(rootState)
            state.logged = false
            tokenStorage.clear()
            // const response = await client.authLogout()
            const response = await client.authLogin(credentials);
            console.log(response)
            commit(userActions.USER_SETTOKEN, response)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return false
        }
    },

    [userActions.USER_DOLOGOUT]: async ({
        dispatch,
        commit
    }) => {
        try {
            const response = await client.authLogout()
            commit(userActions.USER_DOLOGOUT)
            return true
        }
        catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return err
        }
    },

    [userActions.USER_VERIFY]: async ({
        dispatch,
        commit
    }, form) => {
        try {
            const response = await client.authConfirm(form)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            throw err
        }
    },

    [userActions.USER_REGISTER]: async ({
        dispatch,
        commit
    }, form) => {
        try {
            const response = await restAction(authRegister, form, 'POST')
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return false
        }
    },

    [userActions.USER_RESET]: async ({
        dispatch,
        commit
    }, email) => {
        try {
            const response = await restAction(authReset, email, 'POST')
            return true

        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return false
        }
    },

    [userActions.USER_CHECKSALT]: async ({
        dispatch,
        commit
    }, email) => {
        try {
            const response = await restAction(authCheckSalt, email, 'POST')
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return false
        }
    },

    [userActions.USER_CHANGE_PASSWORD]: async ({
        dispatch,
        commit
    }, form) => {
        try {
            const response = await restAction(authChangePassword, form, 'POST')
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return false
        }
    },


}

const mutations = {
    [userActions.USER_SETTOKEN]: (state, response) => {
        tokenStorage.setStorageItem(response)
        const privileges = tokenStorage.getTokenPrivileges()
        console.log(privileges)
        if (privileges !== null) {
            state.privileges = privileges
        }
        state.logged = true
    },

    [userActions.USER_REGISTER]: (state, response) => {
        state.logged = false
    },

    [userActions.USER_DOLOGOUT]: (state) => {
        state.logged = false
        tokenStorage.clear()
    },

    [userActions.USER_SETLOGGIN_STATE]: (state, logState) => {
        state.logged = logState
    },

    [userActions.USER_READTOKEN]: (state) => {
        if (tokenStorage.isAuthenticated()) {
            state.logged = true
            state.privileges = tokenStorage.getTokenPrivileges()
            console.log(state.privileges)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
