export default {
    data: function () {
		return {
            options: {
                dialog: false,
                color: 'red',
                message: '',
                title: ''
            }
		}
	}
}
