export const PROMO_FETCHPROMO = 'promo/fetchpromo'
export const PROMO_UPDATEPROMO = 'promo/updatepromo'
export const PROMO_APPROVEPROMO = 'promo/approvepromo'
export const PROMO_REJECTPROMO = 'promo/rejectpromo'
export const PROMO_DELETEPROMO = 'promo/deletepromo'
export const PROMO_NEWPROMO = 'promo/newpromo'

export const PROMO_LOADING = 'promo/loading'
export const PROMO_LOADED = 'promo/loaded'
export const PROMO_ERROR = 'promo/error'
export const PROMO_RESET = 'promo/reset'
