// import axios from 'axios'
import instance from '../services/axiosInstance'
import i18n from '@/plugins/i18n'
import diff from 'recursive-diff'

import { /* format,*/ compareAsc } from 'date-fns'
// import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const defaultHeaders = {
    'Content-Type': 'application/json'
}

export const wait = (time_ms) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, time_ms);
    })
}

export const restAction = (url, data, method, headers) => {
    return new Promise((resolve, reject) => {
        headers = headers || defaultHeaders
        console.log(headers)
        data = data || {}
        // if (typeof data === 'object' && data['lang'] === undefined) {
        //     data.lang = i18n.locale
        // }
        instance({
                url,
                data,
                method,
                headers
            })
            .then(response => {
                if (typeof response === "object" && response !== null && 'data' in response) {
                    if (typeof response.data === "object" && response.data !== null && 'errors' in response.data) {
                        return reject(response.data.errors[0])
                    }
                    return resolve(response.data)
                }
            })
            .catch(err => {
                let error = err
                if (err.response !== undefined) {
                    error = err.response.data
                }
                return reject(error)
            })
    })
}

export const isEmptyObj = (obj) => {
    for(var i in obj) return false;
    return true;
}

/**
 * Funzione per confrontare la data odierna con un data in ingressp
 * @param {string} date: stringa della data da confrontare
 *
 * @returns: torna il risultato del confronto in questo modo:
 *            1 => la data è < rispetto al giorno corrente
 *            0 => la data coincide con quella odierna
 *           -1 => la data è maggiore rispetto al giorno corrente
 */
export const compareDate = (date) => {
    // prendo l'ora attuale
    let now = new Date()
    // mi imposto a mezzanotte
    now.setHours(0, 0, 0, 0);

    // prendo la data da confrontare e mi metto a mezzanotte
    let zonedDate = new Date(date)
    zonedDate.setHours(0, 0, 0, 0);

    const result = compareAsc(now, zonedDate)
    // 1 now > zonedDate
    // -1 now < zonedDate
    // 0 le date sono uguali
    //console.log(result, now, zonedDate)

    return result
}

export const checkObjectDiff = (current, original) => {
    const diffs = diff.getDiff(current, original)
    const hasChanged = Object.keys(diffs).length !== 0

    return hasChanged
}

export const getKeysDiff = (current, original) => {
    const diffs = diff.getDiff(current, original)
    return diffs
}

export const downloadFileAsync = (url, name) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        const response = await fetch(url)
        const blob = await response.blob()
        const file = new File([blob], name, {type: blob.type})
        console.log(file)
        return resolve(file)
    })
}
