import Vue from 'vue'
import Router from 'vue-router'
import {
    defaultLanguage
} from '@/plugins/i18n'

Vue.use(Router)

import {
    RootView
} from '@/views'

import {
    HomeView,
    FarmsView,
    FarmRootView,
    FarmView,
    StoreRootView,
    StorePromosView,
    PromoView,
    MultiFarmPromosView
} from '../views/dashboard'

import {
    LoginHome,
    SignIn,
    SignUp,
    Verify,
    Reset
} from '../views/login'

export const MultiFarmPromoLoader = () => import("../components/multi/MultiFarmPromoLoader.vue");
export const MultiFarmPromo = () => import("../components/multi/MultiFarmPromo.vue");

export default new Router({
    // mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        console.log('sono lo scrollBehaviour', savedPosition)
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
    routes: [{
            path: '/:locale',
            component: RootView,
            children: [{
                    path: '',
                    component: HomeView,
                    meta: {
                        requiresAuth: true
                    },
                    children: [
                        {
                            path: '',
                            name: 'Farms',
                            component: FarmsView,
                            meta: {
                                requiresAuth: true
                            },
                        },
                        {
                            path: 'multi',
                            component: MultiFarmPromosView,
                            meta: {
                                requiresAuth: true
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'Multi',
                                    component: MultiFarmPromoLoader,
                                    props: true
                                },
                                {
                                    path: 'promo/:promoId',
                                    name: 'MultiPromo',
                                    component: MultiFarmPromo,
                                    props: true
                                },
                            ]
                        },
                        {
                            path: 'group/:id',
                            component: FarmRootView,
                            props: true,
                            meta: {
                                requiresAuth: true
                            },
                            children: [{
                                    path: '',
                                    name: 'Farm',
                                    component: FarmView,
                                    props: true
                                },
                                {
                                    path: 'promo/:promoId',
                                    name: 'Promo',
                                    component: PromoView,
                                    props: true
                                    /*props(route) {
                                        console.log(route)
                                        return { promoId: route.params }
                                    },*/
                                },
                                {
                                    path: 'store/:storeId',
                                    component: StoreRootView,
                                    props: true,
                                    children: [{
                                            path: '',
                                            name: 'StorePromos',
                                            component: StorePromosView,
                                            props: true
                                        },
                                        {
                                            path: 'promo/:promoId',
                                            name: 'StorePromo',
                                            component: PromoView,
                                            props: true
                                            /*props(route) {
                                                console.log(route)
                                                return { promoId: route.params }
                                            },*/
                                        }
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    path: 'login',
                    component: LoginHome,
                    meta: {
                        guest: true
                    },
                    children: [{
                            path: '',
                            name: 'Login',
                            component: SignIn,
                            meta: {
                                guest: true
                            }
                        },
                        {
                            path: 'signup',
                            name: 'Signup',
                            component: SignUp,
                            meta: {
                                guest: true
                            }
                        },
                        {
                            path: 'confirm',
                            name: 'Verify',
                            component: Verify,
                            meta: {
                                guest: true
                            }
                        },
                        {
                            path: 'reset',
                            name: 'Reset',
                            component: Reset,
                            meta: {
                                guest: true
                            }
                        },
                        {
                            path: '*',
                            redirect() {
                                return defaultLanguage;
                            }
                        }
                    ]
                }
            ]
        },
        { // <---------------
            path: '*',
            redirect() {
                return defaultLanguage;
            }
        }
    ]
})
