import TokenStorage from '../services/tokenStorage'
import {
    loadLanguageAsync,
    defaultLanguage,
    supportedLanguages
} from '@/plugins/i18n'

export default function isAuthenticated(router) {
    /**
     * If the user is already authenticated he shouldn't be able to visit
     * pages like login, register, etc...
     */
    router.beforeEach(async (
        to,
        from,
        next
    ) => {
        // prima setto la ligua
        // console.log(to, from)
        let locale = to.params.locale
        // console.log(locale)
        const supported_locales = supportedLanguages.split(',')
        if (!supported_locales.includes(locale)) {
            return next(`/${defaultLanguage}`)
        }
        // await loadLanguageAsync(locale)
        // console.log(to)
        // if (to.matched.length === 0) {
        //     return next(from.path)
        // }
        if (to.matched.some(record => record.meta.requiresAuth)) {

            const isAuth = TokenStorage.isAuthenticated()
            // const isAuth = await TokenStorage.checkIfTokenIsValid()

            console.log('sono autenticato=', isAuth)

            if (isAuth) {
                next()
            } else {
                console.log(locale)
                next({
                    name: 'Login',
                    params: {
                        locale: locale
                    }
                    // path: `${locale}/login`
                })
            }
        } else {
            next()
        }
    })
}
