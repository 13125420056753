import client from 'api-client'
import * as promeActions from '../actions/promeFile'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loaded: false,
    loading: false,
    error: null,
    farmId: 0,
    promeFiles: []
}

const getters = {}

const actions = {
    [promeActions.PROMEFILE_GETFILES]: async ({dispatch, commit}, farmId) => {
        try {
            // console.log('caricolo la promo', data.farmId, data.promoId)
            if (state.farmId === farmId && state.loaded === true) {
                return true
            }
            commit(promeActions.PROMEFILE_LOADING)
            const promeFiles = await client.fetchPromeFiles(farmId)
            commit(promeActions.PROMEFILE_LOADED, {farmId, promeFiles})
            return true
        } catch (err) {
            commit(promeActions.PROMEFILE_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },
    [promeActions.PROMEFILE_REMOVEFILE]: async ({ dispatch }, fileId) => {
        try {
            // console.log('caricolo la promo', data.farmId, data.promoId)
            const result = await client.deletePromeFile(fileId)
            if (result) {
                state.promeFiles = state.promeFiles.filter(x => x.Id !== fileId)
            }
            return result
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    }
}

const mutations = {
    [promeActions.PROMEFILE_LOADING]: (state) => {
        state.loaded = false
        state.loading = false
        state.error = null
        state.farmId = 0
        state.promeFiles = []
    },

    [promeActions.PROMEFILE_LOADED]: (state, data) => {
        state.loaded = true
        state.loading = false
        state.promeFiles = data.promeFiles
        state.farmId = data.farmId
    },

    [promeActions.PROMEFILE_ERROR]: (state, error) => {
        state.error = error
        state.loading = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
