/* eslint-disable no-unused-vars */
import * as snackbarTypes from '../actions/snackbar'

var showTimeOut

const state = {
    showSnackBar: false,
    message: '',
    actionButton: 'OK',
    posX: 'center',
    posY: 'bottom',
    mode: 'multi-line',
    timeout: -1
}

const getters = {
    [snackbarTypes.SNACKBAR_GET_STATUS]: state => state.showSnackBar,
    [snackbarTypes.SNACKBAR_GET_MESSAGE]: state => state.message,
    [snackbarTypes.SNACKBAR_GET_ACTION_BUTTON]: state => state.actionButton,
    [snackbarTypes.SNACKBAR_GET_MODE]: state => state.mode,
    [snackbarTypes.SNACKBAR_GET_X]: state => state.posX,
    [snackbarTypes.SNACKBAR_GET_Y]: state => state.posY,
    [snackbarTypes.SNACKBAR_GET_TIMEOUT]: state => state.timeout
}

const actions = {
    [snackbarTypes.SNACKBAR_SHOW]: ({
        commit,
        dispatch
    }, message) => {
        return new Promise((resolve, reject) => {
            console.log('voglio vedere il messaggio', message)
            //
            if (showTimeOut !== null && showTimeOut !== undefined) {
                clearTimeout(showTimeOut)
            }
            commit(snackbarTypes.SNACKBAR_HIDE)
            setTimeout(() => {
                //
                commit(snackbarTypes.SNACKBAR_SHOW, message)
                //
                showTimeOut = setTimeout(() => {
                    dispatch(snackbarTypes.SNACKBAR_HIDE)
                }, 4500)
            }, 125)

            return resolve()
        })
    },
    [snackbarTypes.SNACKBAR_HIDE]: ({
        commit,
        dispatch
    }) => {
        return new Promise((resolve, reject) => {
            commit(snackbarTypes.SNACKBAR_HIDE)
            return resolve()
        })
    }
}

const mutations = {
    [snackbarTypes.SNACKBAR_HIDE]: (state) => {
        state.showSnackBar = false
    },
    [snackbarTypes.SNACKBAR_SHOW]: (state, message) => {
        console.log('qualcuno vuole vedere un messaggio', message)
        state.showSnackBar = true
        state.message = message
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
