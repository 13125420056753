import * as appActions from '../actions/app'

const state = {
    drawer: true,
    success: 'primary',
    background: '',
    appbarColor: '',
    appFooterColor: '',
    headerHeight: 64,
    playerHeight: 100,
    lang: 'it',
    farmListType: 0,
    farmPromosScrollPosition: 0,
    farmStoresScrollPosition: 0,
    filterBarHeight: 72,
    farmPromosCurrentTab: 0,
    promeFileCurrentTab: 0
}

const getters = {

}

const actions = {

}

const mutations = {
    [appActions.APP_TOGGLEDRAWERSTATUS]: (state) => {
        state.drawer = !state.drawer
    },
    [appActions.APP_SETDRAWERSTATUS]: (state, status) => {
        state.drawer = status
    },
    [appActions.APP_SETAPPBACKGROUND]: (state, background) => {
        state.background = background
    },
    [appActions.APP_SETAPPBARCOLOR]: (state, color) => {
        state.appbarColor = color
    },
    [appActions.APP_SETAPPFOOTERCOLOR]: (state, color) => {
        state.appFooterColor = color
    },
    [appActions.APP_SETFARMLISTTYPE]: (state, type) => {
        state.farmListType = type
    },
    [appActions.APP_SETPROMOS_SCROLLPOSITION]: (state, position) => {
        state.farmPromosScrollPosition = position
    },
    [appActions.APP_SETSTORES_SCROLLPOSITION]: (state, position) => {
        state.farmStoresScrollPosition = position
    },
    [appActions.APP_RESETSCROLLERS]: (state) => {
        state.farmPromosScrollPosition = 0
        state.farmStoresScrollPosition = 0
    },
    [appActions.APP_SETFARMPROMOS_CURRENTTAB]: (state, value) => {
        state.farmPromosCurrentTab = value
    },
    [appActions.APP_SETPROMEFILES_CURRENTTAB]: (state, value) => {
        state.promeFileCurrentTab = value
    },
    [appActions.APP_RESETTABS]: (state) => {
        state.farmPromosCurrentTab = 0
        state.promeFileCurrentTab = 0
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
