export const LoginHome = resolve => {
    require.ensure(['./Login.vue'], () => {
        resolve(require('./Login.vue'))
    }, 'login') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}

export const SignIn = resolve => {
    require.ensure(['./SignIn.vue'], () => {
        resolve(require('./SignIn.vue'))
    }, 'login') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}

export const SignUp = resolve => {
    require.ensure(['./SignUp.vue'], () => {
        resolve(require('./SignUp.vue'))
    }, 'login') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}

export const Verify = resolve => {
    require.ensure(['./Verify.vue'], () => {
        resolve(require('./Verify.vue'))
    }, 'login') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}

export const Reset = resolve => {
    require.ensure(['./Reset.vue'], () => {
        resolve(require('./Reset.vue'))
    }, 'login') // <-- questo parametro indica di mettere tutto il gruppo nello stesso bundle
}
