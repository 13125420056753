import axios from 'axios'
import store from '../store'
import * as userActions from '../store/actions/user'
//import axios from './axios'
import {
    authCheckPath,
    authGetPrivileges,
    authGetContext,
    authRefreshToken
} from '../utils/api'

class TokenStorage {

    constructor() {
        this.tokenStorage = 'rc-self'
    }

    getContext() {
        return new Promise((resolve, reject) => {
            axios
                .get(authGetContext, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.getAuthenticationHeader()
                    }
                })
                .then(response => {
                    console.log(response)
                    return resolve(response)
                })
                .catch(err => {
                    //console.error(err)
                    return reject(err)
                })
        })
    }

    getPrivileges() {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'POST',
                    url: authGetPrivileges,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.getAuthenticationHeader()
                    }
                })
                .then(response => {
                    //console.log(response.data)
                    return resolve(response.data)
                })
                .catch(err => {
                    console.error(err)
                    return reject(err)
                })
        })
    }

    isAuthenticated() {
        try {
            return this.getToken() !== null
            // return this.getRefreshToken() !== null
        } catch (err) {
            return false
        }
    }

    async checkIfTokenIsValid() {
        try {
            const response = await this.getContext()
            const privileges = await this.getPrivileges()
            store.commit(userActions.USER_SETLOGGIN_STATE, true)
            return true
        } catch (err) {
            store.commit(userActions.USER_SETLOGGIN_STATE, false)
            return false
        }
    }

    getAuthenticationHeader() {
        return `Bearer ${this.getToken()}`
    }

    getToken() {
        const storageItem = localStorage.getItem(this.tokenStorage)
        if (storageItem) {
            const parsed = JSON.parse(storageItem)
            return `${parsed.token}`
        }
        return null
    }

    getRefreshToken() {
        const storageItem = localStorage.getItem(this.tokenStorage)
        if (storageItem) {
            const parsed = JSON.parse(storageItem)
            return parsed.refreshToken
        }
        return null
    }

    getNewRefreshToken() {
        return new Promise((resolve, reject) => {
            axios
                .post(authRefreshToken, {
                    "refreshtoken": this.getRefreshToken(),
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.getAuthenticationHeader()
                    }
                })
                .then(response => {
                    this.updateTokens(response.data.token, response.data.refreshToken)
                    return resolve()
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    }

    updateTokens(token, refreshToken) {
        const storageItem = localStorage.getItem(this.tokenStorage)
        if (storageItem) {
            let parsed = JSON.parse(storageItem)
            parsed.token = token
            parsed.refreshToken = refreshToken
            this.setStorageItem(parsed)
            // localStorage.setItem(this.tokenStorage, JSON.stringify(parsed))
            return parsed.token
        }
    }

    getTokenRemainingTime() {
        const storageItem = localStorage.getItem(this.tokenStorage);
        if (!storageItem) {
            return 0;
        }
        const parsed = JSON.parse(storageItem)

        const jwtToken = JSON.parse(atob(parsed.token.split('.')[1]));
        console.log(jwtToken)
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
    }

    getTokenPrivileges() {
        const storageItem = localStorage.getItem(this.tokenStorage);
        if (!storageItem) {
            return null;
        }
        const parsed = JSON.parse(storageItem)

        const jwtToken = JSON.parse(atob(parsed.token.split('.')[1]));
        console.log(jwtToken)
        return JSON.parse(jwtToken.privileges)
    }

    setStorageItem(item) {
        localStorage.setItem(this.tokenStorage, JSON.stringify(item))
    }

    clear() {
        localStorage.removeItem(this.tokenStorage)
    }
}

const tokenStorage = new TokenStorage()

export default tokenStorage
