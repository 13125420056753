<template>
    <v-dialog
        v-model="dialog"
        @keydown.esc="cancel"
        persistent
        width="50%"
    >
        <v-card>
            <v-toolbar :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{
                    options.title
                }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text
                v-show="!!options.message"
            >
                <v-row>
                    <v-col cols="12" class="text-center mt-8">
                        <span class="headline">{{options.message}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary darken-1"
                    text
                    @click.native="agree"
                    :disabled="showProgress"
                    >Conferma</v-btn
                >
                <v-btn
                    color="grey"
                    text
                    @click.native="cancel"
                    :disabled="showProgress"
                    >Annulla</v-btn
                >
            </v-card-actions>
            <v-progress-linear
                v-if="showProgress"
                indeterminate
                color="cyan"
            ></v-progress-linear>
        </v-card>
    </v-dialog>
</template>

<script>
import mixins from "./mixins";

export default {
    data() {
        return {
            dialog: false,
            showProgress: false
        };
    },
    mixins: [mixins],
    methods: {
        mergeData(data) {
            this.options = { ...this.options, ...data };
            this.dialog = true;
        },
        setProgress(show) {
            this.showProgress = show;
        },
        agree() {
            return this.options.promiseResolver();
        },
        cancel() {
            return this.options.promiseRejecter();
        },
        forceCloseAll() {
            this.dialog = false;
        }
    }
};
</script>

<style></style>
