import client from 'api-client'
import * as categoryActions from '../actions/promeCategory'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loaded: false,
    loading: false,
    error: null,
    farmId: 0,
    categories: []
}

const getters = {}

const actions = {
    [categoryActions.PROMECATEGORY_GETCATEGORIES]: async ({dispatch, commit}, farmId) => {
        try {
            console.log('carico categories', farmId)
            if (state.farmId === farmId && state.loaded === true) {
                return true
            }
            console.log('carico categories', farmId)
            commit(categoryActions.PROMECATEGORY_LOADING)
            const categories = await client.fetchPromeCategories(farmId)
            console.log(categories)
            commit(categoryActions.PROMECATEGORY_LOADED, {farmId, categories})
            return true
        } catch (err) {
            commit(categoryActions.PROMECATEGORY_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },
}

const mutations = {
    [categoryActions.PROMECATEGORY_LOADING]: (state) => {
        state.loaded = false
        state.loading = false
        state.error = null
        state.farmId = 0
        state.categories = []
    },

    [categoryActions.PROMECATEGORY_LOADED]: (state, data) => {
        state.loaded = true
        state.loading = false
        state.categories = data.categories
        state.farmId = data.farmId
    },

    [categoryActions.PROMECATEGORY_ERROR]: (state, error) => {
        state.error = error
        state.loading = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
