import client from 'api-client'
import * as multiFarmActions from '../actions/multiFarmPromo'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loaded: false,
    loading: false,
    error: null,
    promos: []
}

const getters = {}

const actions = {
    [multiFarmActions.MULTI_FARM_FETCHPROMOS]: async ({
        dispatch,
        commit
    }) => {
        try {
            if (state.loaded) {
                return true;
            }
            // console.log('caricolo la promo', data.farmId, data.promoId)
            commit(multiFarmActions.MULTI_FARM_LOADING)
            const promos = await client.fetchMultiFarmPromos()

            // console.log(promos);

            commit(multiFarmActions.MULTI_FARM_LOADED, promos)
            // console.log(promo)
            return true;
        } catch (err) {
            commit(multiFarmActions.MULTI_FARM_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null;
        }
    },

    [multiFarmActions.MULTI_FARM_UPDATEPROMO]: async ({
        dispatch,
        commit
    }, promo) => {
        try {
            const formData = new FormData()
            if (promo.imageFile !== undefined) {
                formData.append("icoop_image", promo.imageFile)
                // delete promo.imageFile
            }
            if (promo.audioFile !== undefined) {
                formData.append("icoop_audio", promo.audioFile)
                // delete promo.audioFile
            }
            if (promo.videoFile !== undefined) {
                formData.append("icoop_video", promo.videoFile)
                // delete promo.videoFile
            }
            if (promo.attachFile !== undefined) {
                formData.append("icoop_attachment", promo.attachFile)
                // delete promo.attachFile
            }

            formData.append('promo', JSON.stringify(promo));
            // Object.keys(promo).forEach(key => {
            //     formData.append(key, promo[key])
            // })

            // formData.append('promo', promo)
            // console.log(promo)

            const status = await client.updatePromoMulti(formData)
            return true
        } catch (err) {
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },

    [multiFarmActions.MULTI_FARM_RESET]: async ({
        dispatch,
        commit
    }) => {
        state.loaded = false;
        state.loading = false;
        state.error = null;
        state.promos = [];
    }
}

const mutations = {
    [multiFarmActions.MULTI_FARM_LOADING]: (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
        state.promos = []
    },

    [multiFarmActions.MULTI_FARM_RESET]: (state) => {
        state.loading = false
        state.loaded = false
        state.error = null
        state.promos = []
    },

    [multiFarmActions.MULTI_FARM_LOADED]: (state, promos) => {
        state.promos = promos
        state.loading = false
        state.loaded = true
    },

    [multiFarmActions.MULTI_FARM_ERROR]: (state, error) => {
        state.loading = false
        state.loaded = false
        state.error = error
    },

    [multiFarmActions.MULTI_FARM_APPEND]: (state, promo) => {
        state.promos.push(promo)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
