export const authLogin = '/auth/login'
export const authLogout = '/auth/logout'
export const authRegister = '/auth/register'
export const authGetContext = '/auth/user'
export const authGetPrivileges = '/auth/privileges'
export const authRefreshToken = '/auth/refresh-token'
export const authConfirm = '/auth/confirm'

export const graphQlEndpoint = '/graphql'
export const authCheckPath = '/auth/user'
export const authReset = '/auth/reset'
export const authCheckSalt = '/auth/checksalt'
export const authChangePassword = '/auth/changepassword'
export const getLang = '/lang'

export const getFarms = '/store/farms'
export const getFarm = '/store/getfarm'
export const getFarmStores = '/store/farmstores'
export const getFarmPromos = '/store/farmpromos'
export const getStorePromoCount = '/store/storepromocount'
export const getStorePromos = '/store/promos'
export const getStore = '/store/store'

export const deletePromo = '/store/deletepromo'
export const updatePromo = '/store/updatepromo'
export const updatePromoMulti = '/store/updatepromomulti'
export const createPromo = '/store/createpromo'
export const approvePromo = '/store/approvepromo'
export const rejectPromo = '/store/rejectpromo'
export const fetchPromo = '/store/fetchpromo'
export const fetchMultiFarmPromos = '/store/fetchpromos'
export const fetchToApprovePromo = '/store/fetchtoapprovepromo'
export const fetchPromeFiles = '/prome/getavailablefiles'
export const deletePromeFile = '/prome/removefile'
export const fetchPromeCategories = 'promecategory/getcategories'
export const fetchPromoCategories = 'promocategory/getcategories'

export const getgruppiconsorzio = '/store/getgruppiconsorzio';
export const getgruppoconsorzio = '/store/getgruppoconsorzio';
export const setgruppoconsorzio = '/store/setgruppoconsorzio';
export const deletegruppoconsorzio = '/store/deletegruppoconsorzio';

export const getStores = '/store/stores'

export const promeFilePlayFile = '/prome/streamFile'
