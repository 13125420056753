<template>
    <v-dialog
            v-model="showProgress"
            persistent
            width="300"
        >
        <v-card
            color="primary"
            >
            <v-card-text>
                {{ options.message }}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import mixins from './mixins'

export default {
    mixins: [mixins],
    data () {
        return {
            showProgress: false
        }
    },
    methods: {
        mergeData(data){
            this.options = { ...this.options, ...data }
            this.showProgress = true
        },
        forceCloseAll() {
            this.showProgress = false
        }
    }
}
</script>

<style>

</style>
