import DialogComponent from './WaitingTaskDialog.vue'

const registeredViews = {}

let Plugin = function(Vue, options) {
    this.Vue = Vue
    this.mounted = false
    this.vuetify = options.vuetify
    this.$root = {} // The root component
    this.globalOptions = {}
}

Plugin.prototype.mountIfNotMounted = function() {
	if (this.mounted === true) {
		return
    }

	this.$root = (() => {
        const vuetify = this.vuetify

		let node = document.createElement('div')
        document.querySelector('body').appendChild(node)

        let DialogConstructor = this.Vue.extend(DialogComponent)
		let Vm = new DialogConstructor({ vuetify })
		Vm.registeredViews = this.registeredComponents()

		return Vm.$mount(node)

	})()

	this.mounted = true
}

Plugin.prototype.registeredComponents = function () {
	return registeredViews
}

Plugin.prototype.registerComponent = function (name, definition) {
	if (this.mounted) {
		this.destroy()
	}

	// registeredViews[name] = this.Vue.extend(definition)
	registeredViews[name] = definition
}

Plugin.prototype.destroy = function () {
	if (this.mounted === true) {

        this.$root.forceCloseAll()

		let elem = this.$root.$el
		this.$root.$destroy()
		this.$root.$off()
		elem.remove()
		this.mounted = false
	}
}

Plugin.prototype.open = function (localOptions = {}) {

    this.mountIfNotMounted()
    localOptions.id = 'dialog.' + Date.now()
    const options = { ...this.globalOptions, ...localOptions }
    this.$root.mergeData(options)
}

Plugin.install = function (Vue, options) {

    Vue.waitingTask = new Plugin(Vue, options)

	Object.defineProperties(Vue.prototype, {
		$waitingTask: {
			get () {
				return Vue.waitingTask
			}
		}
	})
}

export default Plugin
