export const STORE_LOADING = 'stores/loading'
export const STORE_LOADED = 'stores/loaded'
export const STORE_ERROR = 'stores/error'
export const STORE_GET_STORES = 'stores/getstores'

export const STORE_GET_STORE = 'stores/getstore'
export const STORE_GET_PROMO_COUNT = 'stores/getpromocount'
export const STORE_RESET_STORE = 'stores/resetstore'
export const STORE_RESET_STORES = 'stores/resetstores'

export const STORE_UPDATE_PROMO_COUNT = 'stores/updatepromocount'
export const STORE_FETCH_STORES = 'stores/fetchstores'
