import client from 'api-client'
import * as farmpromosActions from '../actions/farmPromos'
import * as snackbarActions from '../actions/snackbar'

const state = {
    loading: false,
    loaded: false,
    error: null,
    promos: []
}

const getters = {}

const actions = {
    [farmpromosActions.FARMPROMOS_FETCH_PROMOS]: async ({ dispatch, commit }, farmId) => {
        try {
            if(state.loaded) {
                return
            }
            commit(farmpromosActions.FARMPROMOS_LOADING)
            const promos = await client.fetchFarmPromos(farmId)
            commit(farmpromosActions.FARMPROMOS_LOADED, promos)
            return true
        }
        catch (err) {
            commit(farmpromosActions.FARMPROMOS_ERROR, err)
            dispatch(snackbarActions.SNACKBAR_SHOW, err)
            return null
        }
    },
}

const mutations = {
    [farmpromosActions.FARMPROMOS_LOADING]: (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
        state.promos = []
    },

    [farmpromosActions.FARMPROMOS_RESET]: (state) => {
        state.loading = false
        state.loaded = false
        state.error = null
        state.promos = []
    },

    [farmpromosActions.FARMPROMOS_LOADED]: (state, promos) => {
        state.promos = promos
        state.loading = false
        state.loaded = true
    },

    [farmpromosActions.FARMPROMOS_ERROR]: (state, error) => {
        state.loading = false
        state.loaded = false
        state.error = error
    },

    [farmpromosActions.FARMPROMOS_APPEND]: (state, promo) => {
        state.promos.push(promo)
    },

    [farmpromosActions.FARMPROMOS_REMOVE]: (state, promoId) => {
        state.promos = state.promos.filter(promo => {
            return promo.IdComunicazioneGenerica !== promoId
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
