export const FILTER_STORES_SET_TEXT = 'filters/setstoretext'
export const FILTER_PROMOS_SET_TEXT = 'filters/setpromotext'
export const FILTER_MULTIFARMSPROMOS_SET_TEXT = 'filters/setmultifarmpromotext'
export const FILTER_PROMEFILES_SET_TEXT = 'filters/setpromefilestext'
export const FILTER_CLUSTERS_SET_TEXT = 'filters/setclusterstext'


export const FILTER_STORES_SET_SWITCH = 'filters/setstoreswitch'
export const FILTER_PROMOS_SET_SWITCH = 'filters/setpromoswitch'
export const FILTER_MULTIFARMSPROMOS_SET_SWITCH = 'filters/setmultifarmpromoswitch'
export const FILTER_PROMEFILES_SET_SWITCH = 'filters/setpromefileswitch'

export const FILTER_STORES_RESET = 'filters/resetstoresfilter'
